import { createRouter, createWebHistory } from "vue-router";
import { getToken } from "@/utils/auth";

const whiteList = [
  "/login",
  "/register",
  "/reset",
  "/map",
  "/checkdisk",
  "/contract/details",
];

const routes = [
  {
    path: "/",
    name: "noAuth",
    component: () => import("../views/noAuth.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/newHome",
    name: "newHome",
    component: () => import("../views/newHome.vue"),
  },
  {
    path: "/contract/list",
    name: "ContractList",
    component: () => import("../views/contract/List.vue"),
  },
  {
    path: "/contract/add",
    name: "ContractAdd",
    component: () => import("../views/contract/Add.vue"),
  },
  {
    path: "/contract/details",
    name: "ContractDetails",
    component: () => import("../views/contract/Details.vue"),
  },
  {
    path: "/contract/bill",
    name: "ContractBill",
    component: () => import("../views/contract/bill.vue"),
  },
  {
    path: "/model/list",
    name: "ModeltList",
    component: () => import("../views/model/List.vue"),
  },
  {
    path: "/model/addAndEdit",
    name: "ModelAddAndEdit",
    component: () => import("../views/model/AddAndEdit.vue"),
  },
  {
    path: "/model/detail",
    name: "ModelDetail",
    component: () => import("../views/model/Detail.vue"),
  },
  {
    path: "/device/list",
    name: "DeviceList",
    component: () => import("../views/device/List.vue"),
  },
  {
    path: "/device/addAndEdit",
    name: "DeviceAddAndEdit",
    component: () => import("../views/device/AddAndEdit.vue"),
  },
  {
    path: "/device/detail",
    name: "DeviceDetail",
    component: () => import("../views/device/Detail.vue"),
  },
  {
    path: "/receipt/addAndEdit",
    name: "PaymentAdd",
    component: () => import("../views/receipt/AddAndEdit.vue"),
  },
  {
    path: "/receipt/detail",
    name: "PaymentDetail",
    component: () => import("../views/receipt/Detail.vue"),
  },
  {
    path: "/entry/add",
    name: "EntryOrderAdd",
    component: () => import("../views/entryOrder/Add.vue"),
  },
  {
    path: "/entry/list",
    name: "EntryOrderList",
    component: () => import("../views/entryOrder/List.vue"),
  },
  {
    path: "/entry/details",
    name: "EntryOrderDetails",
    component: () => import("../views/entryOrder/Details.vue"),
  },
  {
    path: "/checkdisk",
    name: "checkDisk",
    component: () => import("../views/entryOrder/checkDisk.vue"),
  },

  {
    path: "/quit/list",
    name: "QuitOrderList",
    component: () => import("../views/quitOrder/List.vue"),
  },
  {
    path: "/quit/details",
    name: "QuitOrderDetails",
    component: () => import("../views/quitOrder/Details.vue"),
  },
  {
    path: "/quit/add",
    name: "QuitOrderAdd",
    component: () => import("../views/quitOrder/Add.vue"),
  },
  {
    path: "/renter/list",
    name: "RenterAdd",
    component: () => import("../views/renter/List.vue"),
  },
  {
    path: "/renter/user",
    name: "RenterUser",
    component: () => import("../views/renter/User.vue"),
  },
  {
    path: "/renter/userDetail",
    name: "UserDetail",
    component: () => import("../views/renter/Detail.vue"),
  },
  {
    path: "/approval/list",
    name: "ApprovalAdd",
    component: () => import("../views/approval/List.vue"),
  },
  {
    path: "/submit/list",
    name: "SubmitAdd",
    component: () => import("../views/submit/List.vue"),
  },
  {
    path: "/store/list",
    name: "StoreList",
    component: () => import("../views/store/List.vue"),
  },
  {
    path: "/authToUser/list",
    name: "UserList",
    component: () => import("../views/authToUser/List.vue"),
  },
  {
    path: "/sendSignTask",
    name: "sendSignTask",
    component: () => import("../views/sendSignTask/index.vue"),
  },
  {
    path: "/preview",
    name: "preview",
    component: () => import("../views/preview/index.vue"),
  },
  {
    path: "/overview",
    name: "overview",
    component: () => import("../views/overview/index.vue"),
  },
  {
    path: "/advertisement/iot",
    name: "advertisement",
    component: () => import("../views/advertisement/iot.vue"),
  },
  {
    path: "/todoTask",
    name: "todoTask",
    component: () => import("../views/todoTask/index.vue"),
  },
  {
    path: "/finishedTask",
    name: "finishedTask",
    component: () => import("../views/finishedTask/index.vue"),
  }, {
    path: "/mine",
    name: "mine",
    component: () => import("../views/mine/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (getToken()) {
    if (to.path === "/login") {
      next({ path: "/home" });
    } else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
