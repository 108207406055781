import VueCookies from 'vue-cookies';

export function getToken() {
  return VueCookies.get('uid');
}

export function setToken(token) {
  return VueCookies.set('token', token);
}

export function removeToken() {
  VueCookies.remove('username');
  VueCookies.remove('sessionId');
  VueCookies.remove('uid');
  VueCookies.remove('token');
  VueCookies.remove('type');
  VueCookies.remove('ver');
  VueCookies.remove('nickName');
  VueCookies.remove('appid');
  VueCookies.remove('roleId');
  return true;
}
